export default function prepareAudioFile(episode) {
  let src =
    process.env.NODE_ENV !== 'production'
      ? episode.audio.src && `${episode.audio.src.replace('backend', 'localhost')}`
      : episode.audio.src;
  return {
    title: episode.title,
    audio: {
      src,
      type: episode.audio.type,
    },
    link: `/${episode.uid}`,
  };
}
